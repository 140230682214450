/* eslint-disable react/no-unescaped-entities */
import GooglePlay from "../../../assets/google-play.svg"
import AppStore from "../../../assets/app-store.svg"
import CloseIcon from "../../../assets/close-icon.svg"
import { Link, NavLink, useLocation } from "react-router-dom"
import { Title } from "../../UI"
import {
  FooterS,
  DownloadS,
  DownloadWith,
  FooterContent,
  Info,
  MenuLinks,
  Copyright,
  LineS,
} from "./styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from "@mui/material"
import { privacyIndents, termIndents } from "../../../data"
import { IIndent, Indent } from ".."
import { FC, useState } from "react"
import { isMobile, isAndroid } from "react-device-detect"
import { useSendPageView } from "../../../hooks"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../config"

type DialogVariant = "termsDialog" | "privacyDialog"

interface Props {
  indents: IIndent[]
  popupTitle: string
}

const IndentList: FC<Props> = ({ indents, popupTitle }) => {
  useSendPageView({ title: popupTitle + " (Popup)" })

  return (
    <>
      {indents.map((indent) => (
        <Indent key={indent.id} indent={indent} />
      ))}
    </>
  )
}

export const Footer = () => {
  const fullScreen = useMediaQuery("(max-width: 1024px)")
  const [toggleDialog, setToggleDialog] = useState<DialogVariant | null>()
  const { pathname } = useLocation()

  const handleCloseDialog = () => {
    setToggleDialog(null)
  }

  const handleOpenDialog = (dialogName: DialogVariant) => {
    return () => {
      dialogName === "termsDialog"
        ? setToggleDialog("termsDialog")
        : setToggleDialog("privacyDialog")
    }
  }

  const sendAnalyticsHandler = (platform: "ios" | "android") => {
    return () => {
      logEvent(analytics, "download_app", {
        name: platform,
      })
    }
  }

  return (
    <>
      <FooterS>
        {pathname === "/" && (
          <DownloadS>
            <Title
              styles={`
      font-size: 32px;
      font-weight: 600;
      line-height: 44px;
      color: rgba(20, 20, 27, 1);
          `}
            >
              Download app to your phone
            </Title>
            <DownloadWith>
              {isMobile ? (
                isAndroid ? (
                  <Link
                    to='https://play.google.com/store/apps/details?id=ai.lifebuddy.app'
                    target={"_blank"}
                    onClick={sendAnalyticsHandler("android")}
                  >
                    <img src={GooglePlay} alt='google-play' />
                  </Link>
                ) : (
                  <Link
                    to='https://apps.apple.com/app/lifebuddy-ai-ai-life-coach/id6503171740'
                    target={"_blank"}
                    onClick={sendAnalyticsHandler("ios")}
                  >
                    <img src={AppStore} alt='app-store' />
                  </Link>
                )
              ) : (
                <>
                  <Link
                    to='https://play.google.com/store/apps/details?id=ai.lifebuddy.app'
                    target={"_blank"}
                    onClick={sendAnalyticsHandler("android")}
                  >
                    <img src={GooglePlay} alt='google-play' />
                  </Link>
                  <Link
                    to='https://apps.apple.com/app/lifebuddy-ai-ai-life-coach/id6503171740'
                    target={"_blank"}
                    onClick={sendAnalyticsHandler("ios")}
                  >
                    <img src={AppStore} alt='app-store' />
                  </Link>
                </>
              )}
            </DownloadWith>
          </DownloadS>
        )}
        {pathname === "/success" && <LineS />}
        <FooterContent>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Info>
              LifeBuddy is a trademark belonging to OPEN KEYS LIMITED company registered in Hong
              Kong SAR
            </Info>
            <MenuLinks>
              <NavLink to='/contact-us'>Contact</NavLink>
              <NavLink to='/q&a'>Q&A</NavLink>
              <NavLink to='/introduction'>Introduction</NavLink>
              <a onClick={handleOpenDialog("termsDialog")}>Terms of Services</a>
              <a onClick={handleOpenDialog("privacyDialog")}>Privacy Policy</a>
            </MenuLinks>
            <Copyright>© 2024 LifeBuddy</Copyright>
          </div>
        </FooterContent>
      </FooterS>
      <Dialog fullScreen={fullScreen} open={toggleDialog === "termsDialog"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {"Terms of Services"}{" "}
          <IconButton onClick={handleCloseDialog}>
            <img src={CloseIcon} alt='close-button' />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            fontSize: "14px",
          }}
        >
          <IndentList indents={termIndents} popupTitle='Terms of Services' />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={toggleDialog === "privacyDialog"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {"Privacy Policy"}{" "}
          <IconButton onClick={handleCloseDialog}>
            <img src={CloseIcon} alt='close-button' />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            fontSize: "14px",
          }}
        >
          <DialogContentText>
            This Privacy Policy the "Policy" describes how OPEN KEYS LIMITED "Developer" collects,
            uses, and discloses your personal information when you use the mobile application the
            "App".
          </DialogContentText>
          <IndentList indents={privacyIndents} popupTitle='Privacy Policy' />
        </DialogContent>
      </Dialog>
    </>
  )
}

import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import {
  Button,
  Page,
  Wrapper,
  Form,
  MobileHeader,
  Header,
} from "../../components"
import { Issue } from "./types"
import { IssueChoiceWrapperS, IssueChoiceS, LayoutS, ButtonWrapS, ErrorMessage } from "./styles"
import { useSendPageView } from "../../hooks"
// import { logEvent } from "firebase/analytics"
// import { analytics } from "../../config"
import imgSelected from "../../assets/box-selected.svg"
import imgUnselected from "../../assets/box-unselected.svg"
import { AppContext } from "../../store"

export const IssuesQuestionnaire = () => {
  const issues: Issue[] = [
    {
      title: "Stress, Anxiety, and Overwhelm",
      content: [
        "Do you often feel overwhelmed or stressed by life’s demands?",
        "Are you struggling to manage anxiety in your daily life?"
      ]
    },
    {
      title: "Relationship Issues and Communication Problems",
      content: [
        "Are conflicts or misunderstandings affecting your relationships?",
        "Do you want to communicate more effectively and build stronger connections?"
      ]
    },
    {
      title: "Work-Life Balance and Burnout",
      content: [
        "Is it difficult to balance work with personal life?",
        "Do you feel overworked, leading to stress or burnout?"
      ]
    },
    {
      title: "Motivation, Goal Setting, and Personal Growth",
      content: [
        "Do you struggle to find motivation for your goals?",
        "Need help setting and achieving personal or professional goals?"
      ]
    },
    {
      title: "Career Dissatisfaction and Transitions",
      content: [
        "Are you unhappy or unfulfilled in your current career?",
        "Considering a career change but unsure where to start?"
      ]
    },
    {
      title: "Social Skills, Shyness, and Loneliness",
      content: [
        "Do you feel shy or anxious in social situations?",
        "Want to build confidence and meaningful relationships?"
      ]
    },
    {
      title: "Decision-Making Difficulties and Fear of Failure",
      content: [
        "Do you often feel indecisive or doubt your choices?",
        "Is fear of failure preventing you from taking action?"
      ]
    },
    {
      title: "Health, Wellness, and Lifestyle Changes",
      content: [
        "Do you want to adopt a healthier lifestyle or improve well-being?",
        "Struggling to maintain fitness, nutrition, or wellness goals?"
      ]
    },
    {
      title: "Identity, Self-Discovery, and Finding Purpose",
      content: [
        "Are you questioning your life direction or purpose?",
        "Interested in exploring your identity and discovering your true self?"
      ]
    },
    {
      title: "Financial Stress and Money Management",
      content: [
        "Are financial worries causing you stress or anxiety?",
        "Need guidance to improve your finances and reduce money stress?"
      ]
    },
    {
      title: "Depression and Low Mood",
      content: [
        "Are you experiencing sadness or a lack of motivation?",
        "Have you lost interest in activities you once enjoyed?"
      ]
    },
    {
      title: "Anger Management and Emotional Regulation",
      content: [
        "Do you have difficulty controlling anger or emotions?",
        "Are intense emotions affecting your relationships or work?"
      ]
    },
    {
      title: "Mindfulness and Emotional Well-being",
      content: [
        "Interested in practicing mindfulness to enhance well-being?",
        "Want techniques to manage stress and stay present?"
      ]
    },
    {
      title: "Addictive Behaviors and Breaking Bad Habits",
      content: [
        "Struggling with habits or behaviors you wish to change?",
        "Need support to break free from addictive patterns?"
      ]
    },
    {
      title: "Life Transitions and Adjustments",
      content: [
        "Are you navigating a significant life change and finding it challenging?",
        "Need support to adapt to new life circumstances?"
      ]
    },
    {
      title: "Parenting Challenges",
      content: [
        "Facing difficulties in parenting or family dynamics?",
        "Looking for strategies to improve parenting and family relationships?"
      ]
    },
    {
      title: "Grief, Loss, and Healing from Trauma",
      content: [
        "Struggling to cope with a recent loss or past trauma?",
        "Seeking support to heal and move forward?"
      ]
    },
    {
      title: "Communication Skills and Assertiveness",
      content: [
        "Want to communicate more confidently and assertively?",
        "Looking to enhance your interpersonal skills for growth?"
      ]
    },
    {
      title: "Gender-Specific Concerns",
      content: [
        "Seeking support for challenges unique to your gender experience?",
        "Want guidance on gender-specific empowerment and growth?"
      ]
    }
  ]

  const { dispatch } = useContext(AppContext);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [showError, setShowError] = useState(false)

  function handleCheckboxChange(id: string) {
    if (selectedIssues.includes(id)) {
      setSelectedIssues(selectedIssues.filter((selected) => selected !== id));
    } else if (selectedIssues.length < 2) {
      setSelectedIssues([...selectedIssues, id]);
    }
  };

  const isButtonEnabled = selectedIssues.length > 0;

  const navigate = useNavigate()
  const {
    // register,
    handleSubmit,
    formState: { errors },
  } = useForm<string[]>({
    mode: "onSubmit",
  })

  const onSubmit = (data: string[]) => {
    if (!isButtonEnabled) {
      setShowError(true)
      return
    }

    // logEvent(analytics, "save_profile", userData)
    dispatch({ type: "SET_TOPICS", payload: selectedIssues })
    navigate("/onboarding/choose-coach")
  }

  useSendPageView({ title: "Onboarding - Step 2 - Select 1 or 2 areas you want to improve and see results" })

  const onInvalid = () => {
    setShowError(true)
  }

  return (
    <LayoutS>
      <Header title='Select 1 or 2 areas you want to improve and see results' />
      <Page paddingBottom="0">
        <MobileHeader title='Select 1 or 2 areas you want to improve and see results' previousPath='/registration-flow' />
        <Form className="form" onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <Wrapper>
            <IssueChoiceWrapperS>
              <div className="subheader">Don’t worry, you will be able to add more or change them later</div>
              {issues.map((issue) => (
                <IssueChoiceS
                  key={issue.title}
                >
                  <div className="header-wrapper">
                    <img
                      src={selectedIssues.includes(issue.title) ? imgSelected : imgUnselected}
                      alt={issue.title}
                      onClick={() => handleCheckboxChange(issue.title)}
                    />
                    <div className="header">{issue.title}</div>
                  </div>
                  {issue.content.map((paragraph) => (
                    <div key={paragraph}>{paragraph}</div>
                  ))}
                  
                </IssueChoiceS>
              ))}
            </IssueChoiceWrapperS>
            {showError && !isButtonEnabled && <ErrorMessage>Issue not selected</ErrorMessage>}
          </Wrapper>
          <ButtonWrapS>
            <Button
              type='submit'
              variant='next'
              size='normal'
              disabled={showError || !isButtonEnabled}
            >
              Submit
            </Button>
          </ButtonWrapS>
        </Form>
      </Page>
    </LayoutS>
  )
}

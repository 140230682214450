import { OnBoardingLayout } from "../../components"
import { useSendPageView } from "../../hooks"
import image from "../../assets/on-boarding-img-2.png";

export const OnBoardingComplete = () => {
  useSendPageView({ title: "Onboarding - Step 5 - Register to get time-limited offer" })

  const pageData = [
    {
      header: "Enjoy Naturally Flowing Discussions with Human-Like AI Coaches",
      content: "Interact with AI coaches modeled after successful human coaches, offering personalized and evolving sessions.",
    },
    {
      header: "Flexible and Accessible Coaching",
      content: "Enjoy flexible sessions, tailored to fit your schedule and preferences.",
    },
    {
      header: "Customize Your Experience with Engaging Activities",
      content: "Lively coaching sessions, personality tests, role-play exercises uncovering your untapped potential, guided meditations, and more—all customizable to your needs.",
    },
  ];

  return (
    <>
      <OnBoardingLayout
        image={image}
        data={pageData}
        backgroundColor="#f2dfdf"
        buttonText="Register to get time-limited offer"
        buttonLink="/success"
        backgroundImagePosition="left"
      ></OnBoardingLayout>
    </>
  )
}

import { logEvent } from "firebase/analytics"
import { useEffect } from "react"
import { analytics } from "../config"

interface Props {
  title: string
}

export const useSendPageView = ({ title }: Props) => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      "page_title": "LifeBuddy.ai - " + title,
    })
  }, [])
}

import { Header, MobileHeader, Slider } from "../../components"
import { useSendPageView } from "../../hooks"

export const Home = () => {
  useSendPageView({ title: "Onboarding - Step 3 - Select your coach" })

  return (
    <>
      <Header title='Select your coach' />
      <MobileHeader title='Select your coach' isHomePage={true} />
      <Slider />
    </>
  )
}
